import { MessagesSeverityType } from "primereact/messages";

const MessageType = {
  SUCCESS: "success" as MessagesSeverityType,
  WARNING: "warn" as MessagesSeverityType,
  INFO: "info" as MessagesSeverityType,
  ERROR: "error" as MessagesSeverityType,
};

export default MessageType;
