import UserReadDto from "./model/user/UserReadDto";

export default class AuthData {
  token: string;
  currentUser: UserReadDto;

  constructor(token: string, currentUser: UserReadDto) {
    this.token = token;
    this.currentUser = currentUser;
  }
}
